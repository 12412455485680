.spinner-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    // background-color: rgba(232, 228, 228, 0.404);
    background: rgb(255 255 255 / 32%);
    z-index: 9999;
    .spinner {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // height:60px;
        // width:60px;
        // margin:0px auto;
        // -webkit-animation: rotation .6s infinite linear;
        // -moz-animation: rotation .6s infinite linear;
        // -o-animation: rotation .6s infinite linear;
        // animation: rotation .6s infinite linear;
        // border-left:6px solid rgba(0,174,239,.15);
        // border-right:6px solid rgba(0,174,239,.15);
        // border-bottom:6px solid rgba(0,174,239,.15);
        // border-top:6px solid rgba(0,174,239,.8);
        
            position: fixed;
            background-color: var(--color-heading);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99999999;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            border-radius:100%;
        .loader-img {
            width: 50px !important;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-name: bounce;
            animation-name: bounce;
            -webkit-animation-duration: 2s;
            animation-duration: 2s;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            background-blend-mode: multiply;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
        @keyframes bounce {
            0%, 100%, 20%, 50%, 80% {
                -webkit-transform: translateY(0);
                -ms-transform: translateY(0);
                transform: translateY(0);
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
            40% {
                -webkit-transform: translateY(-30px);
                -ms-transform: translateY(-30px);
                transform: translateY(-30px);
            }
            60% {
                -webkit-transform: translateY(-15px);
                -ms-transform: translateY(-15px);
                transform: translateY(-15px);
            }
        }
      }
  }
  