/* .scss file */
$bgcolor: #ffffff;
$textcolor: #1f1f1f;
$bordercolor: #bec3c947;
$borderhover: #8b949e;
$textlink: #616569;
$buttonborder: #e5cdc25c;
$inputbordercolor: #c9c9c9c4;
$font-cs-bold: 'CircularStdBold';
$font-cs-medium: 'CircularStdMedium';
$font-cs-book: 'CircularStdBook';
$theme-color: #e2531c;