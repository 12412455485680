body {
  margin: 0;
  font-family: 'CircularStdBook';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  /* background: rgb(238,166,127); */
  /* background: radial-gradient(circle, rgba(238,166,127,1) 0%, rgba(255,255,255,1) 100%); */
  /* background: linear-gradient(rgba(8, 15, 53, 0), rgba(0, 0, 10, 0.9)) no-repeat fixed, linear-gradient(333deg, rgba(153, 207, 255, 0.2), rgba(180, 255, 217, 0.08)), radial-gradient(circle at 77% 89%, rgba(125, 163, 169, 0.8), rgba(125, 163, 169, 0) 50%), radial-gradient(circle at 15% 95%, rgba(125, 163, 169, 0.8), rgba(125, 163, 169, 0) 43%), radial-gradient(circle at 65% 23%, rgba(137, 151, 119, 0.4), rgba(137, 151, 119, 0) 70%), radial-gradient(circle at 10% 0%, rgba(187, 211, 204, 0.33), rgba(187, 211, 204, 0) 35%), radial-gradient(circle at 11% 100%, rgba(131, 165, 203, 0.3), rgba(131, 165, 203, 0) 30%) rgb(8, 15, 53); */
  /* background: linear-gradient(269deg, #000000, #6c6969); */
  /* background: linear-gradient(237deg, #e4631e, #ffffff); */
  /*   background-size: 400% 400%;
  background-repeat: no-repeat; */
  /* animation: AnimationName 30s ease infinite; */
  /* animation: gradient 15s ease infinite; */

}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


/* Fonts */
@font-face {
  font-family: "CircularStdBook";
  src: local("CircularStdBook"),
    url("./asset/font/circularstd/CircularStd-Book.ttf") format("truetype");
}

@font-face {
  font-family: "CircularStdMedium";
  src: local("CircularStdMedium"),
    url("./asset/font/circularstd/CircularStd-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "CircularStdBold";
  src: local("CircularStdBold"),
    url("./asset/font/circularstd/CircularStd-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "CatamaranExtraBold";
  src: local("CatamaranExtraBold"),
    url("./asset/font/Catamaran/static/Catamaran-ExtraBold.ttf") format("truetype");
}

/* Fonts End */

code {
  font-family: 'CircularStdBook';
}

.MuiButton-root {
  text-transform: none !important;
}

@keyframes AnimationName {
  0% {
    background-position: 0% 11%
  }

  50% {
    background-position: 100% 90%
  }

  100% {
    background-position: 0% 11%
  }
}